import { nanoid } from '@reduxjs/toolkit'
import { useMemo, useState } from 'react'
import {
    useAddComment,
    useCollapseComment,
    useComments,
    useExpandComment,
    useIsCommentCollapsed,
    useSelectCommentReference,
} from '../state/comments/hooks'
import { useSetPageNumber } from '../state/pdf/hooks'
import { Comment } from '../types'
import { timeAgo } from '../utils/time'
import { Icons } from './Icons'

type CommentProps = {
    comment: Comment
}

const CommentHeader = ({ comment }: CommentProps) => {
    const collapse = useCollapseComment()
    const expand = useExpandComment()
    const isCollapsed = useIsCommentCollapsed(comment.commentId)
    return (
        <div className="flex flex-row gap-2 items-end">
            {isCollapsed ? (
                <Icons.PlusCircle
                    className="w-4 h-4 cursor-pointer mb-1"
                    onClick={() => {
                        expand(comment.commentId)
                    }}
                />
            ) : (
                <Icons.MinusCircle
                    className="w-4 h-4 cursor-pointer mb-1"
                    onClick={() => {
                        collapse(comment.commentId)
                    }}
                />
            )}

            <p className="underline">{comment.author.name}</p>
            <p className="text-sm italic">{timeAgo(comment.timestamp)}</p>
        </div>
    )
}

const CommentQuote = ({ comment }: CommentProps) => {
    const selectCommentReference = useSelectCommentReference()
    const setPageNumber = useSetPageNumber()
    return comment.paperReference ? (
        <blockquote
            className="p-4 my-1 border-s-4 border-gray-300 bg-gray-50 cursor-pointer"
            onMouseEnter={() => selectCommentReference(comment.paperReference)}
            onMouseLeave={() => selectCommentReference(null)}
            onClick={() => setPageNumber(comment.paperReference!.pageNumber)}
        >
            <p className="italic leading-relaxed text-gray-900">
                {comment.paperReference.selectionText}
            </p>
        </blockquote>
    ) : (
        <></>
    )
}

const CommentReplyBar = ({ comment }: CommentProps) => {
    const [showReplyModal, setShowReplyModal] = useState(false)
    const [replyContent, setReplyContent] = useState('')
    const addComment = useAddComment()
    return (
        <>
            {showReplyModal ? (
                <div className="flex flex-row gap-1 w-full items-center">
                    <input
                        type="form"
                        className="border border-gray-200 rounded p-1 flex-grow italic"
                        value={replyContent}
                        placeholder="Write a comment.."
                        onChange={(e) => setReplyContent(e.target.value)}
                    />
                    <button
                        className="bg-blue-500 rounded-xl px-2 py-1 text-white font-semibold"
                        onClick={() => {
                            addComment({
                                commentId: nanoid(),
                                parentId: comment.commentId,
                                author: comment.author,
                                timestamp: Date.now(),
                                paperReference: null,
                                comment: replyContent,
                            })
                            setReplyContent('')
                            setShowReplyModal(false)
                        }}
                    >
                        Post
                    </button>
                    <button
                        className="bg-red-500 rounded-xl px-2 py-1 text-white font-semibold"
                        onClick={() => setShowReplyModal(false)}
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <div className="flex flex-row">
                    <button onClick={() => setShowReplyModal(true)}>
                        Reply
                    </button>
                </div>
            )}
        </>
    )
}

const CommentComponent = ({ comment }: CommentProps) => {
    const isCollapsed = useIsCommentCollapsed(comment.commentId)
    return (
        <div className="border border-slate-400 rounded flex flex-col p-1">
            <CommentHeader comment={comment} />
            {isCollapsed ? (
                <></>
            ) : (
                <>
                    <CommentQuote comment={comment} />
                    <div className="ml-2">{comment.comment}</div>
                    <CommentReplyBar comment={comment} />
                </>
            )}
        </div>
    )
}

type Node = {
    value: Comment | null
    children: Node[]
}

const buildCommentTree = (comments: Comment[]) => {
    // we start with a 'hidden' root node so we can maintain a single tree, rather than
    // separate trees for each top-level comment
    const rootNode: Node = { value: null, children: [] }

    comments.forEach((comment) => {
        if (comment.parentId === null) {
            // this comment has no parent, so just add it to the 'root' node
            rootNode.children.push({ value: comment, children: [] })
        } else {
            // find node with BFS and add this as a child

            const queue = [rootNode]
            const visited = new Set()

            while (queue.length) {
                const node = queue.shift()
                if (!node) {
                    break
                }

                if (node.value?.commentId === comment.parentId) {
                    node.children.push({ value: comment, children: [] })
                    break
                }

                if (!visited.has(node)) {
                    visited.add(node)

                    node.children.forEach((child) => queue.push(child))
                }
            }
        }
    })

    return rootNode
}

type RenderNodeProps = {
    node: Node
}

const RenderNode = ({ node }: RenderNodeProps) => {
    const { value, children } = node
    // recursively render nodes in the tree with left-margin to provide threaded effect
    return (
        <div>
            {value && <CommentComponent comment={value} />}
            {children.map((child, index) => (
                /* the root node has value `null` therefore this ternary does not indent the root node*/
                <div key={index} className={value ? 'ml-6' : ''}>
                    <RenderNode node={child} />
                </div>
            ))}
        </div>
    )
}

const ViewComments = () => {
    const comments = useComments()

    const commentTree = useMemo(() => buildCommentTree(comments), [comments])

    return <RenderNode node={commentTree} />
}

export default ViewComments
