import { useCallback } from 'react'
import { Comment, PaperReference } from '../../types'
import { useAppDispatch, useAppSelector } from '../hooks'
import {
    addComment,
    collapseComment,
    expandComment,
    makeCommentSelection,
    makeSelection,
} from './reducer'

export const useAddComment = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (comment: Comment) => dispatch(addComment(comment)),
        [dispatch]
    )
}

export const useMakeSelection = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (paperReference: PaperReference | null) =>
            dispatch(makeSelection(paperReference)),
        [dispatch]
    )
}

export const useSelectCommentReference = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (paperReference: PaperReference | null) =>
            dispatch(makeCommentSelection(paperReference)),
        [dispatch]
    )
}

export const useCollapseComment = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (commentId: string) => dispatch(collapseComment(commentId)),
        [dispatch]
    )
}

export const useExpandComment = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (commentId: string) => dispatch(expandComment(commentId)),
        [dispatch]
    )
}

export const useComments = () => {
    return useAppSelector((state) => state.comments.comments)
}

export const usePaperReference = () => {
    return useAppSelector((state) => state.comments.paperReference)
}

export const useSelectedCommentReference = () => {
    return useAppSelector((state) => state.comments.selectedCommentReference)
}

export const useIsCommentCollapsed = (commentId: string) => {
    return useAppSelector(
        (state) => state.comments.commentsCollapsed[commentId]
    )
}
