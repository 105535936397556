import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PdfState {
    currentPage: number
    numberOfPages: number
}

const initialState: PdfState = { currentPage: 0, numberOfPages: 0 }

const pdfSlice = createSlice({
    name: 'pdf',
    initialState,
    reducers: {
        setNumberOfPages(state, action: PayloadAction<number>) {
            state.numberOfPages = action.payload
        },
        setPageNumber(state, action: PayloadAction<number>) {
            state.currentPage = action.payload
        },
    },
})

export const { setNumberOfPages, setPageNumber } = pdfSlice.actions
export default pdfSlice.reducer
