import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Comment, PaperReference } from '../../types'

export interface CommentState {
    comments: Comment[]
    commentsCollapsed: Record<string, boolean>
    paperReference: PaperReference | null
    selectedCommentReference: PaperReference | null
}

const initialState: CommentState = {
    comments: [],
    commentsCollapsed: {},
    paperReference: null,
    selectedCommentReference: null,
}

const commentSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        addComment(state, action: PayloadAction<Comment>) {
            state.comments.push(action.payload)
        },
        makeSelection(state, action: PayloadAction<PaperReference | null>) {
            state.paperReference = action.payload
        },
        makeCommentSelection(
            state,
            action: PayloadAction<PaperReference | null>
        ) {
            state.selectedCommentReference = action.payload
        },
        collapseComment(state, action: PayloadAction<string>) {
            state.commentsCollapsed[action.payload] = true
        },
        expandComment(state, action: PayloadAction<string>) {
            state.commentsCollapsed[action.payload] = false
        },
    },
})

export const {
    addComment,
    makeSelection,
    makeCommentSelection,
    collapseComment,
    expandComment,
} = commentSlice.actions
export default commentSlice.reducer
