import { pdfjs, Document, Page } from 'react-pdf'

import { useCallback, useState } from 'react'
import { PDFDocumentProxy } from 'pdfjs-dist'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { PaperReference } from '../types'
import {
    TextContent,
    TextItem,
    TextMarkedContent,
} from 'pdfjs-dist/types/src/display/api'
import {
    usePaperReference,
    useSelectedCommentReference,
} from '../state/comments/hooks'
import {
    useNumberOfPages,
    usePageNumber,
    useSetNumberOfPages,
    useSetPageNumber,
} from '../state/pdf/hooks'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
).toString()

type PdfComponentProps = {
    file: string
    pdfRef: React.RefObject<HTMLDivElement>
}

type SanitizedTextItem = {
    str: string
    indexWithoutBreak: number
}

const isTextItem = (item: TextItem | TextMarkedContent): item is TextItem => {
    return (item as TextItem).str !== undefined
}

const sanitizePageContent = (textContent: TextContent) => {
    let indexWithoutBreak = 0
    return textContent.items
        .filter(isTextItem)
        .reduce<SanitizedTextItem[]>((acc, textItem) => {
            const { str, hasEOL } = textItem

            return hasEOL && str === ''
                ? [...acc, { str, indexWithoutBreak: indexWithoutBreak }]
                : [...acc, { str, indexWithoutBreak: indexWithoutBreak++ }]
        }, [])
}
const containsIndex =
    (itemIndex: number) => (paperReference: PaperReference | null) => {
        return (
            paperReference &&
            itemIndex >= paperReference.startPosition.nodeIndex &&
            itemIndex <= paperReference.endPosition.nodeIndex
        )
    }

const correctPage =
    (pageNumber: number) => (paperReference: PaperReference | null) => {
        return paperReference && pageNumber === paperReference.pageNumber
    }

const applyHighlight =
    (itemIndex: number) =>
    (text: string, paperReference: PaperReference | null) => {
        if (!paperReference) {
            return text
        }
        if (
            itemIndex === paperReference.startPosition.nodeIndex &&
            itemIndex === paperReference.endPosition.nodeIndex
        ) {
            return `${text.slice(0, paperReference.startPosition.offset)}<mark key=${itemIndex}>${text.slice(paperReference.startPosition.offset, paperReference.endPosition.offset)}</mark>${text.slice(paperReference.endPosition.offset)}`
        }
        if (itemIndex === paperReference.startPosition.nodeIndex) {
            return `${text.slice(0, paperReference.startPosition.offset)}<mark key=${itemIndex}>${text.slice(paperReference.startPosition.offset)}</mark>`
        }
        if (itemIndex === paperReference.endPosition.nodeIndex) {
            return `<mark key=${itemIndex}>${text.slice(0, paperReference.endPosition.offset)}</mark>${text.slice(paperReference.endPosition.offset)}`
        }
        return `<mark key=${itemIndex}>${text}</mark>`
    }

const ReactPdfComponent = ({ file, pdfRef }: PdfComponentProps) => {
    const [pageContent, setPageContent] = useState<SanitizedTextItem[]>([])
    const pageNumber = usePageNumber()
    const setPageNumber = useSetPageNumber()
    const numberOfPages = useNumberOfPages()
    const setNumberOfPages = useSetNumberOfPages()
    const paperReference = usePaperReference()
    const selectedCommentReference = useSelectedCommentReference()

    const customTextRenderer = useCallback(
        (
            textItem: {
                pageIndex: number
                pageNumber: number
                itemIndex: number
            } & TextItem
        ) => {
            const { itemIndex: index, pageNumber } = textItem
            const correctIndex = pageContent[index].indexWithoutBreak
            const str = pageContent[index].str

            return containsIndex(correctIndex)(
                paperReference ?? selectedCommentReference
            )
                ? correctPage(pageNumber)(
                      paperReference ?? selectedCommentReference
                  )
                    ? applyHighlight(correctIndex)(
                          str,
                          paperReference ?? selectedCommentReference
                      )
                    : str
                : str
        },
        [pageContent, paperReference, selectedCommentReference]
    )

    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setPageNumber(1)
        setNumberOfPages(numPages)
    }

    const onTextLayerLoadSuccess = (textContent: TextContent) => {
        setPageContent(sanitizePageContent(textContent))
    }

    const gotoNextPage = () => {
        if (pageNumber < numberOfPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const gotoPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-around gap-2 items-center">
                <button
                    className="bg-white font-semibold py-2 px-4 border border-gray-400 shadow"
                    onClick={gotoPreviousPage}
                >
                    {'<'}
                </button>
                {pageNumber}
                <button
                    className="bg-white font-semibold py-2 px-4 border border-gray-400 shadow"
                    onClick={gotoNextPage}
                >
                    {'>'}
                </button>
            </div>
            <div
                ref={pdfRef}
                className="border-slate-600 border-2 rounded-xl m-4"
            >
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        pageNumber={pageNumber}
                        customTextRenderer={customTextRenderer}
                        onGetTextSuccess={onTextLayerLoadSuccess}
                    />
                </Document>
            </div>
        </div>
    )
}

export default ReactPdfComponent
