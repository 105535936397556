import ReactPdfComponent from './components/ReactPdfComponent'
import { useRef } from 'react'
import WriteComment from './components/WriteComment'
import ViewComments from './components/ViewComments'

function App() {
    const pdfRef = useRef<HTMLDivElement>(null)

    return (
        <div className="flex flex-row">
            <ReactPdfComponent pdfRef={pdfRef} file="raft.pdf" />
            <div className="w-full overflow-auto h-screen p-2">
                <WriteComment pdfRef={pdfRef} />
                <ViewComments />
            </div>
        </div>
    )
}

export default App
