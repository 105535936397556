//type WriteCommentProps = {}

import { nanoid } from '@reduxjs/toolkit'
import { useState } from 'react'
import { usePdfSelection } from '../hooks/usePdfSelection'
import {
    useAddComment,
    useMakeSelection,
    usePaperReference,
} from '../state/comments/hooks'
import { Icons } from './Icons'

type WriteCommentProps = {
    pdfRef: React.RefObject<HTMLElement>
}

const WriteComment = ({ pdfRef }: WriteCommentProps) => {
    const [comment, setComment] = useState('')
    const addComment = useAddComment()
    const { selection, clearSelection } = usePdfSelection(pdfRef)
    const makeSelection = useMakeSelection()

    const paperReference = usePaperReference()

    const user = { id: 'foo', name: 'test user' }
    return (
        <form className="mb-2 border border-black rounded">
            {paperReference && (
                <div className="w-full flex flex-col gap-1 p-3 ">
                    <blockquote className="p-2 my-1 border-s-4 border-gray-300 bg-gray-50 flex-row flex items-center">
                        <p className="italic leading-relaxed text-gray-900 flex-grow">
                            {paperReference.selectionText}
                        </p>
                        <Icons.XMark
                            className="right-6 absolute w-4 h-4 bg-red-400 text-white rounded-xl cursor-pointer "
                            onClick={(e) => {
                                e.preventDefault()
                                // clears state in local hook
                                clearSelection()
                                // dispatch
                                makeSelection(null)
                            }}
                        />
                    </blockquote>
                </div>
            )}
            <div className="w-full border border-gray-200 bg-gray-50 rounded">
                <textarea
                    id="comment"
                    className="w-full p-1 text-sm text-gray-900 bg-white border-0"
                    value={comment}
                    placeholder="Write a comment.."
                    onChange={(e) => setComment(e.target.value)}
                ></textarea>
            </div>
            <div className="flex items-center justify-between px-3 py-2 border-t">
                <button
                    type="submit"
                    className="inline-flex items-center py-2.5 px-4 text-center text-white bg-blue-700 rounded-lg"
                    onClick={(e) => {
                        e.preventDefault()
                        addComment({
                            commentId: nanoid(),
                            author: user,
                            comment,
                            timestamp: Date.now(),
                            paperReference,
                            parentId: null,
                        })
                        makeSelection(null)
                        setComment('')
                    }}
                >
                    Post comment
                </button>
                <div className="flex ps-0 space-x-1 rtl:space-x-reverse">
                    <button
                        type="button"
                        className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 text-md"
                        onClick={(e) => {
                            e.preventDefault()
                            makeSelection(selection)
                        }}
                    >
                        <Icons.DocumentText className="w-4 h-4" />
                        <span className="sr-only">Reference to paper</span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default WriteComment
