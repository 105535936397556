/**
 * Extracts a start and end Node from a selection
 * @param selection - The selection
 * @returns An object containing start/end nodes with offsets or null
 */
export const getSelectionStartEndNodes = (
    selection: Selection | null
): {
    startNode: Node
    startOffset: number
    endNode: Node
    endOffset: number
} | null => {
    if (!selection || !selection.anchorNode || !selection.focusNode) {
        return null
    }

    // create a range to see if user selected text from right -> left
    const range = document.createRange()
    range.setStart(selection.anchorNode, selection.anchorOffset)
    range.setEnd(selection.focusNode, selection.focusOffset)
    const backwards = range.collapsed

    // `anchorNode` is the first node selected, whilst `focusNode` is the last
    const startNode = backwards ? selection.focusNode : selection.anchorNode
    const startOffset = backwards
        ? selection.focusOffset
        : selection.anchorOffset
    const endNode = backwards ? selection.anchorNode : selection.focusNode
    const endOffset = backwards ? selection.anchorOffset : selection.focusOffset

    return { startNode, startOffset, endNode, endOffset }
}
