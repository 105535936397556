import { useEffect, useState } from 'react'
import { usePageNumber } from '../state/pdf/hooks'
import { PaperReference } from '../types'
import { getSelectionStartEndNodes } from '../utils/selection'

export const usePdfSelection = (
    pdfRef: React.RefObject<HTMLElement>
): { selection: PaperReference | null; clearSelection: () => void } => {
    const [selection, setSelection] = useState<PaperReference | null>(null)
    const pageNumber = usePageNumber()

    useEffect(() => {
        const onSelectionChange = () => {
            if (!pdfRef.current) {
                clearSelection()
                return
            }
            const selection = window.getSelection()

            const tree = document.createTreeWalker(
                pdfRef.current,
                NodeFilter.SHOW_ELEMENT,
                (node) => {
                    return node.nodeName === 'DIV' ||
                        node.nodeName === 'CANVAS' ||
                        node.nodeName === 'MARK'
                        ? NodeFilter.FILTER_SKIP
                        : NodeFilter.FILTER_ACCEPT
                }
            )
            let text = ''
            let started = false
            let index = 0
            let startIndex = 0
            let endIndex = 0

            const nodes = getSelectionStartEndNodes(selection)
            if (!nodes) {
                clearSelection()
                return
            }

            const { startNode, startOffset, endNode, endOffset } = nodes

            while (tree.nextNode()) {
                if (
                    tree.currentNode.textContent === startNode.textContent &&
                    tree.currentNode.textContent === endNode.textContent
                ) {
                    text += tree.currentNode.textContent?.slice(
                        startOffset,
                        endOffset
                    )
                    startIndex = index
                    endIndex = index
                    break
                } else if (
                    tree.currentNode.textContent === startNode?.textContent
                ) {
                    startIndex = index
                    started = true
                    text += tree.currentNode.textContent?.slice(startOffset)
                } else if (
                    tree.currentNode.textContent === endNode?.textContent
                ) {
                    endIndex = index
                    started = false
                    text += tree.currentNode.textContent?.slice(0, endOffset)
                    break
                } else if (started) {
                    text += tree.currentNode.textContent
                    if (tree.currentNode.nodeName === 'BR') {
                        text += ' '
                    }
                }
                if (tree.currentNode.nodeName !== 'BR') {
                    index += 1
                }
            }

            if (startIndex === endIndex && startOffset === endOffset) {
                clearSelection()
            } else {
                setSelection({
                    startPosition: {
                        nodeIndex: startIndex,
                        offset: startOffset,
                    },
                    endPosition: {
                        nodeIndex: endIndex,
                        offset: endOffset,
                    },
                    pageNumber,
                    selectionText: text,
                })
            }
        }

        document.addEventListener('selectionchange', onSelectionChange)
        return () => {
            document.removeEventListener('selectionchange', onSelectionChange)
        }
    }, [pdfRef, pageNumber])

    const clearSelection = () => setSelection(null)

    return { selection, clearSelection }
}
