import { configureStore } from '@reduxjs/toolkit'
import comments from './comments/reducer'
import pdf from './pdf/reducer'

export const store = configureStore({
    reducer: {
        comments,
        pdf,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
