import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { setNumberOfPages, setPageNumber } from './reducer'

export const useSetNumberOfPages = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (numberOfPages: number) => dispatch(setNumberOfPages(numberOfPages)),
        [dispatch]
    )
}

export const useSetPageNumber = () => {
    const dispatch = useAppDispatch()
    return useCallback(
        (pageNumber: number) => dispatch(setPageNumber(pageNumber)),
        [dispatch]
    )
}

export const useNumberOfPages = () => {
    return useAppSelector((state) => state.pdf.numberOfPages)
}

export const usePageNumber = () => {
    return useAppSelector((state) => state.pdf.currentPage)
}
